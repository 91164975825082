<template>
	<div class="report-google">
		<div class="report__head">
			<h4 v-if="title" class="report__title">{{ title }}</h4>
		</div> <!-- /.report__head -->

		<div v-if="documentID" class="report__form" ref="formReport">

			<div class="selects">
				<FieldSelect
					placeholder="Action"
					:searchable=false
					v-model.trim="formData.currentAction"
					:options="availableActions[formData.currentTemplate]"
					@input="handleCurrentActionChange"
				>
				</FieldSelect>
				<FieldSelect
					placeholder="Template"
					:searchable=false
					v-model.trim="formData.currentTemplate"
					:options="availableTemplates"
					@input="handleCurrentTemplateChange"
				>
				</FieldSelect>
			</div>

			<div class="submit" v-if="!loading">
				<Button
					tabindex="0"
					@onClick.prevent="submit"
					className="tertiary"
				>
					Submit
				</Button>
			</div>
			<Spinner v-if="loading" size="large" />
		</div> <!-- /.report__form -->
		<div v-if="formData.currentTemplate === 'HHMI' && formData.currentAction === 'generate'" class="HHMI-params">
			<table>
				<tr>
					<th></th>
					<th>DataSeer ID</th>
				</tr>
				<tr v-for="(item, index) in [0,1,2,3,4]" :key="index">
					<td>
						Report {{ index + 1 }}
					</td>
					<td>
						<Field
							name="ids"
							type="text"
							v-model.trim="formData.ids[index]"
							placeholder=""
						>
					</Field>
					</td>
				</tr>
			</table>
		</div>

		<PopupDraggable ref="openReportsPopup" id="openReportsPopup" name="import-reports-popup" size="small">
				<Grid rowGap="medium">
					<GridColumn>
						<h6>List of available {{ formData.currentTemplate.replace(/\s?Report/gmi, "") }} Reports</h6>
					</GridColumn>
					<GridColumn>
						<div v-if="reportsLinks.length === 0">
							No report found
						</div>
						<ul v-else id="reportsLinksList">
							<li v-for="link in reportsLinks" :key="link.id">
								<a :title="`Created on ${formatDate(link.createdAt)}. Last modified on ${formatDate(link.updatedAt)} by ${link.lastModifier}`" :href="link.url" target="_blank" @click="openLink($event, link)"> {{ link.name }}</a>
								<br/><span>Created on {{formatDate(link.createdAt)}}. Last modified on {{formatDate(link.updatedAt)}} by {{link.lastModifier}}</span>
							</li>
						</ul>
					</GridColumn>
				</Grid>
		</PopupDraggable>
	</div> <!-- /.report-google -->
</template>

<script>

/**
 * External Dependencies
 */
import { mapGetters } from 'vuex';

/**
 * Internal Dependencies
 */
import API from '@/lib/datasets/api';
import URLMANAGER from '@/lib/datasets/url';
import Button from '@/components/button/button';
import Spinner from '@/components/spinner/spinner';
import FieldSelect from '@/components/field-select/field-select';
import Field from '@/components/field/field';
import PopupDraggable from '@/components/popup/popup-draggable';
import Grid, { GridColumn } from '@/components/grid/grid';

import documentsService from '@/services/documents/documents';

export default {
	/**
	 * Name
	 */
	name: 'ReportGoogle',

	/**
	 * Props
	 */
	props: {
		title: String,
		documentID: String,
		organizations: Array,
		documentToken: String
	},

	/**
	 * Components
	 */
	components: {
		Button,
		Spinner,
		Field,
		PopupDraggable,
		Grid,
		GridColumn,
		FieldSelect
	},

	/**
	 * Data
	 */
	data: function() {
		return {
			reportsLinks: [],
			loading: false,
			default: {
				availableTemplates: [
					{ value:'AmNat', label:'AmNat Report', enabled: false },
					{ value:'ASAP', label:'ASAP Report', enabled: false },
					{ value:'ASAP-PPMI', label:'ASAP - PPMI Report', enabled: false },
					{ value:'ASAP-GP2', label:'ASAP - GP2 Report', enabled: false },
					{ value:'bioRxiv', label:'BioRxiv Report', enabled: false },
					{ value:'DataSeer Generic', label:'DataSeer Generic Report', enabled: false },
					{ value:'Universal Journal Report', label:'Universal Journal Report', enabled: false },
					{ value:'HHMI', label:'HHMI Report', enabled: false },
					{ value:'MDAR Report', label:'MDAR Report', enabled: false },
					{ value:'MDAR Pub Report', label:'MDAR Pub Report', enabled: false },
					{ value:'MJFF Impact Report', label:'MJFF Impact Report', enabled: false },
				],
				availableActions: {
					"AmNat": [
						{ value:'open', label:'Open', enabled: false },
						{ value:'generate', label:'Generate', enabled: false },
					],
					"ASAP": [
						{ value:'open', label:'Open', enabled: false },
						{ value:'generate', label:'Generate', enabled: false },
					],
					"ASAP-PPMI": [
						{ value:'open', label:'Open', enabled: false },
						{ value:'generate', label:'Generate', enabled: false },
					],
					"ASAP-GP2": [
						{ value:'open', label:'Open', enabled: false },
						{ value:'generate', label:'Generate', enabled: false },
					],
					"bioRxiv": [
						{ value:'open', label:'Open', enabled: false },
						{ value:'generate', label:'Generate', enabled: false },
					],
					"DataSeer Generic": [
						{ value:'open', label:'Open', enabled: false },
						{ value:'generate', label:'Generate', enabled: false },
					],
					"Universal Journal Report": [
						{ value:'open', label:'Open', enabled: false },
						{ value:'generate', label:'Generate', enabled: false },
					],
					"HHMI": [
						{ value:'open', label:'Open', enabled: false },
						{ value:'generate', label:'Generate', enabled: false },
					],
					"MDAR Report": [
						{ value:'open', label:'Open', enabled: false },
						{ value:'generate', label:'Generate', enabled: false },
					],
					"MDAR Pub Report": [
						{ value:'open', label:'Open', enabled: false },
						{ value:'generate', label:'Generate', enabled: false },
					],
					"MJFF Impact Report": [
						{ value:'open', label:'Open', enabled: false },
						{ value:'generate', label:'Generate', enabled: false },
					]
				},
			},
			formData: {
				ids: [this.documentID,'','','',''],
				currentTemplate: '',
				currentAction: ''
			}
		};
	},

	/**
	 * Computed
	 */
	computed: {
		...mapGetters('account', ['userOrganizationsIds', 'userRoleWeight']),

		availableTemplates() {
			return this.default.availableTemplates.filter((item) => {
					return item.enabled;
				});
		},
		availableActions() {
			return {
				"AmNat": this.default.availableActions["AmNat"].filter((item) => {
					return item.enabled;
				}),
				"ASAP": this.default.availableActions["ASAP"].filter((item) => {
					return item.enabled;
				}),
				"ASAP-PPMI": this.default.availableActions["ASAP-PPMI"].filter((item) => {
					return item.enabled;
				}),
				"ASAP-GP2": this.default.availableActions["ASAP-GP2"].filter((item) => {
					return item.enabled;
				}),
				"bioRxiv": this.default.availableActions["bioRxiv"].filter((item) => {
					return item.enabled;
				}),
				"DataSeer Generic": this.default.availableActions["DataSeer Generic"].filter((item) => {
					return item.enabled;
				}),
				"Universal Journal Report": this.default.availableActions["Universal Journal Report"].filter((item) => {
					return item.enabled;
				}),
				"HHMI": this.default.availableActions["HHMI"].filter((item) => {
					return item.enabled;
				}),
				"MDAR Report": this.default.availableActions["MDAR Report"].filter((item) => {
					return item.enabled;
				}),
				"MDAR Pub Report": this.default.availableActions["MDAR Pub Report"].filter((item) => {
					return item.enabled;
				}),
				"MJFF Impact Report": this.default.availableActions["MJFF Impact Report"].filter((item) => {
					return item.enabled;
				}),
			};
		}
	},

	/**
	 * Methods
	 */
	methods: {
		formatDate(str) {
			let date = new Date(str)
			let day = date.getDate();
			let month = date.getMonth() + 1; // Months are zero-based
			let year = date.getFullYear();

			// Add leading zero if day or month is less than 10
			// yyyy-mm-dd
			day = day < 10 ? '0' + day : day;
			month = month < 10 ? '0' + month : month;

			return `${year}-${month}-${day}`;
		},
		openLink(event) {
			let element = event.target;
			if (element) element.classList.add('visited');
		},
		async getOpenReportLinks() {
			let kind = this.formData.currentTemplate === 'bioRxiv' ? 'html' : 'gSpreadsheets';
			// Case bioRxiv -> not a GoogleSpreadsheets report
			if (this.formData.currentTemplate === 'bioRxiv') {
				return [
					URLMANAGER.buildURL(
						`documents/${this.documentID}/reports/${kind}/${this.formData.currentTemplate}`,
						{
							token: this.documentToken
						},
						{ origin: true }
					)
				];
			} else {
				let query = await documentsService.getDocumentGoogleReportsInfos({ id: this.documentID, kind: this.formData.currentTemplate }, { token: this.documentToken });
				if (!query || query.err) return [];
				if (query.res.length <= 0) return [];
				return query.res.map((info) => {
					return { ...info, url: `https://docs.google.com/spreadsheets/d/${info.id}` };
				});
			} 
		},
		async submit() {
			this.$emit('submit', {action: this.formData.currentAction, template: this.formData.currentTemplate});
			this.loading = true;
			this.reportsLinks = [];
			if (this.formData.currentAction === 'open') {
				let reportsLinks = await this.getOpenReportLinks();
				this.$emit('response', {err: false, res: {action: this.formData.currentAction, template: this.formData.currentTemplate, res: reportsLinks}});
				this.reportsLinks = reportsLinks;
				this.loading = false;
				return this.$refs.openReportsPopup.showModal();
			}
			if (this.formData.currentTemplate === 'HHMI' && this.formData.currentAction === 'generate') {
				return API.documents._buildGSpreadSheetReport({
						kind: this.formData.currentTemplate,
						data: {
							ids: this.formData.ids
						}
					}, async (err, query) => {
						this.loading = false;
						this.$emit('response', {err: err, res: {action: this.formData.currentAction, template: this.formData.currentTemplate, res: query}});
						if (!query || query.err) return alert('An error has occured. Please contact an administrator.');
						return window.open(`https://docs.google.com/spreadsheets/d/${query.res}`, '_blank')?.focus();
					});
			}
			let opts = {
					kind: this.formData.currentTemplate,
					id: this.documentID
				};
			return API.documents.buildGSpreadSheetReport(opts, async (err, query) => {
				this.loading = false;
				this.$emit('response', {err: err, res: {action: this.formData.currentAction, template: this.formData.currentTemplate, res: query}});
				if (!query || query.err) return alert('An error has occured. Please contact an administrator.');
				return window.open(`https://docs.google.com/spreadsheets/d/${query.res}`, '_blank')?.focus();
			});
		},
		updateSelectOptions() {
			let organizationsSettings = this.organizations.filter((item) => {
				return this.userRoleWeight >= 1000 || this.userOrganizationsIds.indexOf(item._id) > -1;
			}).map((item) => {
				return item.settings.reports.templates;
			}).flat();
			for (let i = 0; i < organizationsSettings.length; i++) {
				let settings = organizationsSettings[i];
				if (settings.enabled) {
					if (settings.isDefault) this.formData.currentTemplate = settings.label;
					if (settings.actions.open.enabled) {
						let templatesOption = this.default.availableTemplates.filter((item) => { return item.value === settings.label; })[0];
						let actionsOption = this.default.availableActions[settings.label].filter((item) => { return item.value === 'open'; })[0];
						templatesOption.enabled = true;
						actionsOption.enabled = true;
						if (settings.isDefault && settings.actions.open.isDefault) this.formData.currentAction = 'open';
					}
					if (settings.actions.generate.enabled) {
						let templatesOption = this.default.availableTemplates.filter((item) => { return item.value === settings.label; })[0];
						let actionsOption = this.default.availableActions[settings.label].filter((item) => { return item.value === 'generate'; })[0];
						templatesOption.enabled = true;
						actionsOption.enabled = true;
						if (settings.isDefault && settings.actions.generate.isDefault) this.formData.currentAction = 'generate';
					}
				}
			}
		},
		handleCurrentTemplateChange() {
			this.reportsLinks = [];
			this.$refs.openReportsPopup.hideModal();
			let options = this.availableActions[this.formData.currentTemplate].filter((item) => { return item.enabled; });
			let isAvailable = options.filter((item) => { return item.value === this.formData.currentAction; }).length > 0;
			if (isAvailable) return;
			if (options.length > 0) this.formData.currentAction = options[0].value;
		},
		handleCurrentActionChange() {
			this.reportsLinks = [];
			this.$refs.openReportsPopup.hideModal();
		}
	},
	
	/**
	 * Created
	 */
	created () {
		this.updateSelectOptions();
	}
};
</script>
